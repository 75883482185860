import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Main from 'layouts/Main';
import { Container } from '@mui/material';
import p1 from 'assests/Photos/OurEvents/2022/FORMAL.jpg';
import p2 from 'assests/Photos/OurEvents/2022/celebrations.jpg';
import p3 from 'assests/Photos/OurEvents/2022/TRIENNIAL.jpg';


const mock = [
  {
    media: p1,
    href: '/events',
    companyLogo:
      'Formal Official Ceremony',
    description:
      'Formal Official Ceremony',
  },
  {
    media: p2,
    href: '/celebration',
    companyLogo:
      'Celebration',
    description:
      'Celebration',
  },
  
  {
    media: p3,
    href: '/shikar-2022',
    companyLogo:
      'Triennial Events',
    description:
      'Triennial Events',
  },


  

];

const Cont = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Box>
       
       <Typography fontWeight={700} variant="h4" align='center'>
          Events 2022-23
         </Typography>
        <br/>
        <Container maxWidth={0.78} marginTop={0.6} paddingTop={'0 !important'}>
         
        
      <Box marginBottom={4}>
       
      
      </Box>
      <Grid container spacing={2} justifyContent="center">
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              // component={'a'}
              href={''}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={0.8}
                height={0.8}
                borderRadius={1}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  image={item.media}
                  title={item.title}
                  sx={{
                    height: 240,
                  }}
                />
                <Box component={CardContent}>
                  <Box maxWidth={100} marginY={2}>
                    <Box
                      
                      height={1}
                      width={1}
                      src={item.companyLogo}
                      alt="..."
                      sx={{
                        filter: mode === 'dark' ? 'contrast(0)' : 'none',
                      }}
                    />
                  </Box>
                  <Typography
                    align={'left'}
                    variant={'h5'}
                    fontWeight={700}
                  >
                    {item.description}
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box component={CardActions} justifyContent={'flex-start'}>
                  <Button
                    size="large"
                    href={item.href}
                    endIcon={
                      <svg
                        width={16}
                        height={16}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    }
                  >
                    Learn more
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <br/>
      <br/>
      <br/>
      </Container>
      
    </Box>
  );
};

export default Cont;

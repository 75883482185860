import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Container from 'components/Container';
import { Button } from '@mui/material';
import pdf1 from 'assests/Pdf/Results/CLASS102021-22NEW.pdf'
import pdf2 from 'assests/Pdf/Results/CLASS122021-2NEW.pdf'

const mock = [
  {
    name: 'Year 2021-22',
    title: 'Click here',
    
    href:'',
  },
  {
    name: 'Year 2020-21',
    title: 'Click here',
   
    href:'',
  },
  // {
  //   name: 'Year 2019-20',
  //   title: 'Click here',
    
  //   href:'',
  // },
  // {
  //   name: 'Year 2018-19',
  //   title: 'Click here',
   
  //   href:'',
  // },
  // {
  //   name: 'Year 2017-18',
  //   title: 'Click here',
    
  //   href:'',
  // },
  // {
  //   name: 'Year 2016-17',
  //   title: 'Click here',
   
  //   href:'',
  // },
  // {
  //   name: 'Year 2015-16',
  //   title: 'Click here',
   
  //   href:'',
  // },

];

const Archive = () => {
  const theme = useTheme();

  return (
    <Container>
      <Box>
        <Box marginBottom={4}>
          <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            gutterBottom
            color={'text.secondary'}
            align={'center'}
            fontWeight={700}
          >
            ARCHIVES
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                width={0.9}
                height={0.9}
                component={Card}
                boxShadow={0}
                variant={'outlined'}
                bgcolor={'alternate.main'}
              >
                <CardContent sx={{ padding: 3 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                  
                    <Button 
                    href={item.href}>
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.name}
                      secondary={item.title}
                      primaryTypographyProps={{
                        variant: 'h6',
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{ variant: 'subtitle1' }}
                    />
                    </Button>
                  </ListItem>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Archive;

